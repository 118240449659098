/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */

@tailwind base;
@tailwind components;
/* https://www.cssportal.com/google-fonts-css-generator */
@import url('font.css');

@tailwind utilities;

body {
  width: 100%;
  height: 100%;
}

:where(html, body) {
  @apply h-full;
  font-display: swap;
}

:where(html) {
  height: 100%;
  overflow: auto;
}

:where(body) {
  height: auto;
  min-height: 100%;
  @apply flex flex-col text-sm !min-w-full;
}

/* https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */
.grecaptcha-badge {
  visibility: hidden;
}
@layer base {
  :root {
    --radius: 0.5rem;
  }
}
